<template>
  <svg
    width="25"
    height="24"
    viewBox="0 0 25 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle cx="12.9922" cy="12" r="12" fill="#E6ECF4" />
    <g clip-path="url(#clip0_16_36046)">
      <path
        d="M18.9922 16.5H6.99219C6.85958 16.5 6.7324 16.4473 6.63863 16.3535C6.54487 16.2598 6.49219 16.1326 6.49219 16C6.49219 15.8674 6.54487 15.7402 6.63863 15.6464C6.7324 15.5526 6.85958 15.5 6.99219 15.5H18.9922C19.1248 15.5 19.252 15.5526 19.3457 15.6464C19.4395 15.7402 19.4922 15.8674 19.4922 16C19.4922 16.1326 19.4395 16.2598 19.3457 16.3535C19.252 16.4473 19.1248 16.5 18.9922 16.5Z"
        fill="#222F5A"
      />
      <path
        d="M17.9922 18H7.99219C7.85958 18 7.7324 17.9473 7.63863 17.8535C7.54487 17.7598 7.49219 17.6326 7.49219 17.5C7.49219 17.3674 7.54487 17.2402 7.63863 17.1464C7.7324 17.0526 7.85958 17 7.99219 17H17.9922C18.1248 17 18.252 17.0526 18.3457 17.1464C18.4395 17.2402 18.4922 17.3674 18.4922 17.5C18.4922 17.6326 18.4395 17.7598 18.3457 17.8535C18.252 17.9473 18.1248 18 17.9922 18Z"
        fill="#222F5A"
      />
      <path
        d="M5.99219 12.5H5.49219V14C5.49219 14.2652 5.59754 14.5195 5.78508 14.7071C5.97262 14.8946 6.22697 15 6.49219 15H7.99219V14.5C7.99161 13.9697 7.78071 13.4613 7.40576 13.0864C7.03081 12.7114 6.52244 12.5005 5.99219 12.5Z"
        fill="#222F5A"
      />
      <path
        d="M19.9922 11.5H20.4922V9.49997H19.9922C19.1968 9.49906 18.4343 9.1827 17.8719 8.62028C17.3095 8.05787 16.9931 7.29534 16.9922 6.49997V5.99997H8.99219V6.49997C8.99128 7.29534 8.67492 8.05787 8.1125 8.62028C7.55009 9.1827 6.78756 9.49906 5.99219 9.49997H5.49219V11.5C5.64844 11.5 5.81531 11.5 5.99219 11.5C6.78756 11.5009 7.55009 11.8172 8.1125 12.3797C8.67492 12.9421 8.99128 13.7046 8.99219 14.5V15H16.9922V14.5C16.9931 13.7046 17.3095 12.9421 17.8719 12.3797C18.4343 11.8172 19.1968 11.5009 19.9922 11.5ZM12.9922 13.5C12.3988 13.5 11.8188 13.324 11.3255 12.9944C10.8321 12.6647 10.4476 12.1962 10.2205 11.648C9.99349 11.0998 9.93408 10.4966 10.0498 9.9147C10.1656 9.33276 10.4513 8.79821 10.8709 8.37865C11.2904 7.95909 11.825 7.67337 12.4069 7.55761C12.9889 7.44186 13.5921 7.50127 14.1402 7.72833C14.6884 7.95539 15.157 8.33991 15.4866 8.83326C15.8162 9.32661 15.9922 9.90663 15.9922 10.5C15.9913 11.2953 15.6749 12.0579 15.1125 12.6203C14.5501 13.1827 13.7876 13.4991 12.9922 13.5Z"
        fill="#F11C33"
      />
      <path
        d="M12.9922 12.5C14.0968 12.5 14.9922 11.6045 14.9922 10.5C14.9922 9.3954 14.0968 8.49997 12.9922 8.49997C11.8876 8.49997 10.9922 9.3954 10.9922 10.5C10.9922 11.6045 11.8876 12.5 12.9922 12.5Z"
        fill="#222F5A"
      />
      <path
        d="M17.9922 14.5V15H19.4922C19.7574 15 20.0118 14.8946 20.1993 14.7071C20.3868 14.5195 20.4922 14.2652 20.4922 14V12.5H19.9922C19.4619 12.5005 18.9536 12.7114 18.5786 13.0864C18.2037 13.4613 17.9928 13.9697 17.9922 14.5Z"
        fill="#222F5A"
      />
      <path
        d="M19.9922 8.49997H20.4922V6.99997C20.4922 6.73475 20.3868 6.4804 20.1993 6.29286C20.0118 6.10533 19.7574 5.99997 19.4922 5.99997H17.9922V6.49997C17.9928 7.03022 18.2037 7.5386 18.5786 7.91354C18.9536 8.28849 19.4619 8.49939 19.9922 8.49997Z"
        fill="#222F5A"
      />
      <path
        d="M7.99219 6.49997V5.99997H6.49219C6.22697 5.99997 5.97262 6.10533 5.78508 6.29286C5.59754 6.4804 5.49219 6.73475 5.49219 6.99997V8.49997H5.99219C6.52244 8.49939 7.03081 8.28849 7.40576 7.91354C7.78071 7.5386 7.99161 7.03022 7.99219 6.49997Z"
        fill="#222F5A"
      />
    </g>
    <defs>
      <clipPath id="clip0_16_36046">
        <rect
          width="16"
          height="16"
          fill="white"
          transform="translate(4.99219 4)"
        />
      </clipPath>
    </defs>
  </svg>
</template>

<script>
export default {};
</script>

<style>
</style>