<template>
  <div class="flex flex-col p-2 sm:p-6" v-if="isLoad">
    <Khead />

    <div class="flex justify-center items-center">
      <div
        class="
          container
          bg-white
          mt-8
          sm:w-9/12
          md:w-7/12
          lg:w-auto
          rounded
          p-1
          sm:p-5
        "
      >
        <toastSuccess v-if="toastError" :bgToast="'bg-bgk-6'">
          <span slot="icon">
            <IconToastError />
          </span>
          <p class="text-xs sm:text-base">
            {{ $t("rechargeSuccess.toastErrorMessage") }}
          </p>
        </toastSuccess>
        <toastSuccess v-if="toast">
          <p class="text-xs sm:text-base">
            {{ $t("rechargeSuccess.toastMessage") }}
          </p>
        </toastSuccess>
        <toastSuccess v-if="toastDownloadError" :bgToast="'bg-bgk-6'">
          <span slot="icon">
            <IconToastError />
          </span>
          <p class="text-xs sm:text-base">
            {{ $t("rechargeSuccess.downloadErrorMessage") }}
          </p>
        </toastSuccess>
        <div class="p-3 lg:flex justify-between hidden">
          <span><IconSbee /></span>
          <button
            @click="goToFirstPurchase"
            class="
              text-white text-sm
              bg-radio-100
              h-minput
              hover:bg-compteur-600
              rounded
              px-10
              py-2
              focus:outline-none
            "
          >
            {{ $t("rechargeSuccess.newPurchase") }}
          </button>
        </div>
        <div class="p-3 grid grid-cols-1 lg:grid-cols-3 gap-8">
          <div class="lg:hidden grid-cols-1">
            <div class="text-center">
              <img
                class="m-auto"
                src="../../assets/svg/icons/rechargelady.svg"
                alt
              />
              <span class="text-base font-semibold">{{
                $t("rechargeSuccess.titleBill")
              }}</span>
              <br />
             <span
                  class="text-sm"
                  v-html="
                    $t('rechargeSuccess.subtitleBill', {
                      amount: paySuccessData.amount + paySuccessData.fees,
                      meterNumb: paySuccessData.subscriber.name,
                    })
                  "
                ></span>
            </div>
            <button
              @click="goToFirstPurchase"
              class="
                text-white
                mt-4
                text-sm
                bg-radio-100
                h-minput
                hover:bg-compteur-600
                rounded
                px-10
                py-2
                w-full
                focus:outline-none
              "
            >
              {{ $t("rechargeSuccess.newPurchase") }}
            </button>
          </div>

          <div
            class="
              container
              col-span-1
              lg:col-span-3
              border border-kborder-10
              rounded
              shadow
            "
          >
            <div class="flex justify-between items-center">
              <div class="p-3 text-center hidden lg:block">
                <img
                  class="m-auto"
                  src="../../assets/svg/icons/rechargelady.svg"
                  alt
                />
                <span class="text-base font-semibold">{{
                  $t("rechargeSuccess.titleBill")
                }}</span>
                <br />
             <span
                  class="text-sm"
                  v-html="
                    $t('rechargeSuccess.subtitleBill', {
                     amount: formatPrice( paySuccessData.amount + paySuccessData.fees),
                      meterNumb: paySuccessData.subscriber.name,
                    })
                  "
                ></span>
              </div>
              <div>
                <Iconarrow class="hidden lg:block" />
              </div>
              <div class="p-4 w-full lg:w-4/6">
                <div class="">
                  <p class="text-xl lg:text-2xl font-bold mb-2">
                    {{ $t("rechargePay.ptFacture") }}
                  </p>
                  <div class="flex flex-col space-y-4 pr-4">
                    <div class="flex flex-col space-y-2">
                      <facture-line
                        v-for="bill in paySuccessData.bills"
                        :key="bill.invoiceNumber"
                        :dateRange="
                          $t('rechargePay.factureOf') + ' ' + bill.period
                        "
                        :amount="formatPrice(bill.amountToBePaid)"
                      />
                    </div>
                    <div
                      class="
                        lg:flex items-center justify-between flex-row
                      "
                    >
                      <icon-title-label
                        :title="$t('rechargePay.kkpFees')"
                        :amount=" formatPrice(paySuccessData.fees) + ` F CFA`"
                      >
                        <template v-slot:icon>
                          <mtn-icon />
                        </template>
                      </icon-title-label>
                      <icon-title-label
                        title="Total"
                        :amount="
                         formatPrice( paySuccessData.amount + paySuccessData.fees )+ ` F CFA`
                        "
                      >
                        <template v-slot:icon>
                          <cash />
                        </template>
                      </icon-title-label>
                      <icon-title-label
                        title="ID"
                        :amount="paySuccessData.referenceKkiapay"
                      >
                        <template v-slot:icon>
                          <id />
                        </template>
                      </icon-title-label>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="container border border-kborder-10 rounded shadow-xl">
            <div class="flex flex-col justify-between items-center my-4 p-2">
              <div
                class="
                  container
                  mx-auto
                  flex flex-col
                  items-center
                  text-center
                  xl:mb-16
                  my-4
                  p-2
                "
              >
                <Iconbill />
                <span
                  class="mt-3 text-xl font-bold"
                  style="color: #222f5a"
                  v-html="$t('rechargeSuccess.downloadBill')"
                >
                </span>
                <span
                  class="mt-3 text-base"
                  style="color: #222f5a"
                  v-html="$t('rechargeSuccess.downloadText')"
                >
                </span>
              </div>
              <button
                type="button"
                @click="downloadBill"
                class="
                  focus:outline-none
                  text-white
                  py-2
                  w-11/12
                  h-minput
                  bg-kbutton-1
                  hover:bg-kbutton-2
                  active:border active:border-kbutton-3
                  rounded
                  flex
                  items-center
                  justify-center
                "
              >
                <Icondownl v-show="!downloadRequest" />
                <span v-show="!downloadRequest" class="ml-2">{{
                  $t("rechargeSuccess.downloadBtn")
                }}</span>
                <LoaderCss class="ml-16" v-show="downloadRequest" />
              </button>
            </div>
          </div>

          <div class="container border border-kborder-10 rounded shadow-xl">
            <div class="container mx-auto flex flex-col items-center my-4 p-2">
              <Iconsbill />
              <span
                class="mt-3 text-xl font-bold text-center"
                style="color: #222f5a"
                v-html="$t('rechargeSuccess.sendBill')"
              >
              </span>
              <div
                class="mt-3 bg-gray-300 inline-flex w-11/12"
                style="border-radius: 1rem"
              >
                <button
                  @click="billSendMethod"
                  type="button"
                  :class="mail ? 'bg-white transition-all duration-500' : ''"
                  class="
                    focus:outline-none
                    m-2
                    text-gray-800
                    font-bold
                    py-2
                    px-4
                    w-1/2
                    flex
                    justify-center
                  "
                  style="border-radius: 0.75rem"
                >
                  <Iconsmail />
                  <span class="ml-1 text-xs">Email</span>
                </button>
                <!--transform -translate-x-1-->
                <button
                  @click="billSendMethod"
                  type="button"
                  :class="!mail ? 'bg-white transition-all duration-500' : ''"
                  class="
                    focus:outline-none
                    m-2
                    text-gray-800
                    font-bold
                    py-2
                    px-4
                    w-1/2
                    flex
                    justify-center
                  "
                  style="border-radius: 0.75rem"
                >
                  <Iconssms />
                  <span class="ml-1 text-xs">SMS</span>
                </button>
              </div>
              <form
                @submit.prevent="sendBillByMail"
                data-vv-scope="form-1"
                class="mt-2 w-11/12"
                v-show="mail"
              >
                <label class="text-base" for="mail">Email</label>
                <br />
                <div
                  class="
                    bg-white
                    h-minput
                    border-1.5 border-kborder-500
                    hover:border-kinput-3
                    focus-within:border-kinput-2
                    rounded
                    flex flex-row
                    items-center
                  "
                  :class="{
                    ' border-tex-2': submittedMail && errors.has('form-1.mail'),
                  }"
                >
                  <span class="p-3">
                    <Iconmail />
                  </span>
                  <input
                    class="
                      leading-tight
                      w-full
                      focus:outline-none focus:bg-white
                    "
                    id="mail"
                    name="mail"
                    type="email"
                    v-validate="'required|email'"
                    v-model="mailTosend"
                  />
                </div>
                <button
                  type="submit"
                  class="
                    focus:outline-none
                    mt-4
                    text-white
                    py-2
                    w-full
                    h-minput
                    bg-kbutton-1
                    hover:bg-kbutton-2
                    active:border active:border-kbutton-3
                    rounded
                    flex
                    items-center
                    justify-center
                  "
                >
                  <Iconsend v-show="!sendRequest" />
                  <span v-show="!sendRequest" class="ml-2">{{
                    $t("rechargeSuccess.sendBtn")
                  }}</span>
                  <LoaderCss class="ml-16" v-show="sendRequest" />
                </button>
              </form>

              <form
                @submit.prevent="sendBillBySms"
                data-vv-scope="form-2"
                class="mt-2 w-11/12"
                v-show="!mail"
              >
                <label class="text-base" for="sms">{{
                  $t("rechargeSuccess.phone")
                }}</label>
                <br />
                <div>
                  <vue-tel-input
                    inputClasses=""
                    placeholder=""
                    name="sms"
                    inputId="sms"
                    :enabledCountryCode="true"
                    :validCharactersOnly="true"
                    @country-changed="countryChanged"
                    wrapperClasses="h-minput border-1.5 border-kborder-500 hover:border-kinput-3 focus-within:border-kinput-2 rounded"
                    v-model="smsTosend"
                  >
                  </vue-tel-input>
                  <div v-show="phoneError" class="flex items-center mt-1">
                    <IconError /><span class="ml-1 text-tex-2">{{
                      $t("sign.errorNumber")
                    }}</span>
                  </div>
                </div>
                <button
                  type="submit"
                  class="
                    focus:outline-none
                    mt-4
                    text-white
                    py-2
                    w-full
                    h-minput
                    bg-kbutton-1
                    hover:bg-kbutton-2
                    active:border active:border-kbutton-3
                    rounded
                    flex
                    items-center
                    justify-center
                  "
                >
                  <Iconsend v-show="!sendRequest" />
                  <span v-show="!sendRequest" class="ml-2">{{
                    $t("rechargeSuccess.sendBtn")
                  }}</span>
                  <LoaderCss class="ml-16" v-show="sendRequest" />
                </button>
              </form>
            </div>
          </div>

          <div class="container border border-kborder-10 rounded shadow-xl">
            <KsSignUpFirstStep class="w-full" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";

import Iconbill from "../../assets/svg/icons/billicon.svg?inline";

import IconError from "@/assets/svg/icons/errorIcon.svg?inline";
import Khead from "@/components/partials/Khead";
import Iconarrow from "../../assets/svg/icons/redarrow.svg?inline";
import Iconsbill from "../../assets/svg/icons/sendBill.svg?inline";
import Iconsmail from "../../assets/svg/icons/smallmail.svg?inline";
import Iconmail from "../../assets/svg/icons/mail.svg?inline";
import Icondownl from "../../assets/svg/icons/download.svg?inline";
import Iconsend from "../../assets/svg/icons/send.svg?inline";
import Iconssms from "../../assets/svg/icons/smallsms.svg?inline";
import IconToastError from "../../assets/svg/icons/toasterror.svg?inline";
import toastSuccess from "../../components/toastComponents/toastSuccess";
import LoaderCss from "@/components/LoaderCss";
// import Iconcopy from "../../assets/svg/icons/copy.svg?inline";
// import Iconshare from "../../assets/svg/icons/share.svg?inline";
import KsSignUpFirstStep from "@/components/AuthComponents/KsSignUpFirstStep";
import IconSbee from "../../assets/svg/compt2.svg?inline";
import { VueTelInput } from "vue-tel-input";
import FactureLine from "@/components/FactureLine.vue";
import IconTitleLabel from "../../components/IconTitleLabel.vue";
import MtnIcon from "../../components/Icons/MtnIcon.vue";
import Cash from "../../components/Icons/Cash.vue";
import Id from "../../components/Icons/Id.vue";
import {billRechargeApi} from '@/main'

export default {
  components: {
    Khead,
    LoaderCss,
    Iconbill,
    Iconsmail,
    Iconmail,
    IconSbee,
    // IconStsmb,
    // Iconcopy,
    Iconsbill,
    Iconarrow,
    Icondownl,
    Iconsend,
    Iconssms,
    toastSuccess,
    IconError,
    KsSignUpFirstStep,
    IconToastError,
    VueTelInput,
    FactureLine,
    IconTitleLabel,
    MtnIcon,
    Cash,
    Id,
  },
  data() {
    return {
      isLoad:false,
      submittedMail: false,
      submittedSms: false,
      toast: false,
      toastError: false,
      toastDownloadError: false,
      mail: true,
      downloadRequest: false,
      sendRequest: false,
      rechargeDetails: false,
      mailTosend: "",
      smsTosend: "",
      phoneError: false,
      countryCode: null,
      id:''
    };
  },
  watch: {
    smsTosend() {
      return (this.phoneError = false);
    },
  },
  computed: {
    paySuccessData() {
      return this.$store.getters.getPaidBillsData;
    },
  },
  methods: {
    countryChanged(country) {
      this.countryCode = country.dialCode;
    },
    sendBillByMail() {
      if ( this.mailTosend == '') {
          console.log('error');
      }
      else{
        this.sendRequest = true;
      axios
        .post(process.env.VUE_APP_API_BASE_URL + "/notifications/email", {
          transactionId: this.paySuccessData._id,
          email: this.mailTosend,
        })
        .then(() => {
          this.toast = true;
          setTimeout(() => {
            this.toast = false;
          }, 7000);
          this.sendRequest = false;
        })
        .catch((err) => {
          console.log(err);
          this.toastError = false;
          setTimeout(() => {
            this.toastError = false;
          }, 7000);
          this.sendRequest = false;
        });
      }
    },
     formatPrice(amount, delimiter = " ", separator =",") {

      const roundedValue = String(Math.round(amount * 100) / 100);

      const putDelimiter = (value) => {
        const result = [];

        const reversedValue = value.split("").reverse();

        for (let i = 1; i < reversedValue.length + 1; i++) {
          result.push(reversedValue[i - 1]);

          if (i % 3 === 0 && i !== reversedValue.length) {
            result.push(delimiter);
          }
        }

        return result.reverse().join("");
      };
      if (roundedValue.indexOf(separator) !== -1) {
        const [intPart, decPart] = roundedValue.split(separator);

        return [putDelimiter(intPart), ".", decPart].join("");
      }
      return putDelimiter(roundedValue);
    },
    sendBillBySms() {
     if (this.smsTosend == '') {
       console.log('error');
     }
     else{
        this.sendRequest = true;
      axios
        .post(process.env.VUE_APP_API_BASE_URL + "/notifications/sms", {
          transactionId: this.paySuccessData._id,
          phone: (this.countryCode + " " + this.smsTosend).split(" ").join(""),
        })
        .then(() => {
          this.toast = true;
          setTimeout(() => {
            this.toast = false;
          }, 7000);
          this.sendRequest = false;
        })
        .catch((err) => {
          console.log(err);

          this.toastError = false;
          setTimeout(() => {
            this.toastError = false;
          }, 7000);
          this.sendRequest = false;
        });
     }
    },
    billSendMethod() {
      this.mail = !this.mail;
    },
    goToFirstPurchase() {
      this.$store.commit("mutPaymentStep", false);
      this.$store.commit(
        "mutPrevCounterNumber",
        this.paySuccessData.subscriber.reference
      );
      this.$router.push("/recharges");
    },

    downloadBill() {
      axios
        .get(
          process.env.VUE_APP_API_BASE_URL +
            `/transactions/download/${this.paySuccessData._id}`
        )
        .then((response) => {
          window.open(response.data, "_blank");
        });
    },
  },

 
async beforeMount(){
  
  console.log('tt');  this.id = this.$route.query.id;
  // if (this.id == ' ') {
    
  //   this.$router.push('/');
  // }
  

    console.log(this.id,'id');
    await   billRechargeApi.post(`/transactions/validate/${this.id }`)
      .then((res) => {
         this.isLoad= true;
        const data = res.data;
        console.log(data);
        if(data.status == 400){
              console.log(data.status);
        } else if(data.status == 'success'){
          //const purchaseData = data
          this.$store.commit("mutPaidBillsData", data);
        }
      })
      .catch((err) => {
        console.log(err);
      });
     
},
   mounted() {
    
    this.$store.commit("mutPleaseWaitStep", false);
    localStorage.removeItem("sbeeTransaction");
  },
};
</script>

<style scoped>
.elt-flex-sub {
  gap: 40px !important;
}
h1 {
  color: #000;
  font-size: 24px;
  font-weight: 900;
}
.elt-flex {
  display: flex;
  align-items: center;
  gap: 7px;
}
.point-div {
  max-width: 60%;
  margin-left: 20px;
}
.w-100 {
  width: 100%;
}
.vue-tel-input {
  border-radius: 3px;
  display: flex;
  border: 1px solid #c6d6ec;
  text-align: left;
}

.vue-tel-input:hover {
  border-color: #a5bce0;
}
.vue-tel-input:focus-within {
  box-shadow: none;
  border-color: #4661b9;
}

.vue-tel-input:focus {
  outline: none !important;
  border-color: violet;
}

::v-deep .vti__dropdown,
::v-deep .vti__dropdown.open:focus,
::v-deep .vti__dropdown.open:focus-within {
  outline-style: none;
  border-top-left-radius: 0.125rem /* 2px */;
  border-bottom-left-radius: 0.125rem /* 2px */;
}
</style>