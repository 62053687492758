<template>
  <div>
    <div class="flex justify-around space-x-1">
      <div class="flex space-x-0.5 items-center">
        <span>
          <svg
            width="32"
            height="24"
            viewBox="0 0 20 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M9 0.874985C4.51992 0.874985 0.875 4.51991 0.875 8.99998C0.875 13.4801 4.51992 17.125 9 17.125C13.4801 17.125 17.125 13.4801 17.125 8.99998C17.125 4.51991 13.4801 0.874985 9 0.874985ZM13.2285 6.27694L7.97852 12.5269C7.92093 12.5955 7.84927 12.6509 7.76839 12.6894C7.68752 12.7279 7.59932 12.7486 7.50977 12.75H7.49922C7.41162 12.75 7.325 12.7315 7.24499 12.6958C7.16497 12.6602 7.09335 12.6081 7.03477 12.543L4.78477 10.043C4.72762 9.98235 4.68317 9.91093 4.65403 9.8329C4.62488 9.75487 4.61163 9.6718 4.61504 9.58857C4.61846 9.50534 4.63848 9.42364 4.67392 9.34826C4.70936 9.27288 4.75952 9.20534 4.82143 9.14962C4.88335 9.0939 4.95578 9.05112 5.03447 9.0238C5.11315 8.99647 5.19651 8.98514 5.27964 8.99049C5.36276 8.99584 5.44398 9.01775 5.51852 9.05493C5.59305 9.09211 5.6594 9.14382 5.71367 9.20702L7.48281 11.1726L12.2715 5.47303C12.3789 5.34884 12.5309 5.27191 12.6945 5.25887C12.8582 5.24583 13.0205 5.29772 13.1462 5.40333C13.2719 5.50894 13.351 5.65978 13.3664 5.82326C13.3818 5.98673 13.3323 6.1497 13.2285 6.27694Z"
              fill="#27AE60"
            />
          </svg>
        </span>
        <span>{{ dateRange }}</span>
      </div>
      <div class="divider"></div>
      <div class="red">{{ amount }} F CFA</div>
    </div>
  </div>
</template>

<script>
//import Dots from "./Dots.vue";
export default {
  components: {
    //Dots,
  },
  props: ["dateRange", "amount"],
};
</script>

<style scoped>
.ctn {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 10px;
}

.fit-content {
  min-width: fit-content;
}

.red {
  color: #f11c33;
  font-weight: bold;
}
.divider {
  flex-grow: 1;
  border-bottom: 1px dashed black;
  align-self: center;
}
</style>