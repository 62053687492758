<template>
  <svg
    width="25"
    height="24"
    viewBox="0 0 25 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle cx="12.9922" cy="12" r="12" fill="#E6ECF4" />
    <path
      d="M15.3872 6.01817C14.7791 5.36161 13.9297 5.00005 12.9922 5.00005C12.0497 5.00005 11.1975 5.35942 10.5922 6.01192C9.98034 6.67161 9.68222 7.56817 9.75222 8.53629C9.89097 10.4463 11.3444 12 12.9922 12C14.64 12 16.091 10.4466 16.2319 8.53692C16.3028 7.57754 16.0028 6.68286 15.3872 6.01817Z"
      fill="#F11C33"
    />
    <path
      d="M18.4924 19H7.49237C7.34839 19.0019 7.2058 18.9717 7.07498 18.9115C6.94416 18.8513 6.8284 18.7628 6.73612 18.6522C6.53299 18.4094 6.45112 18.0779 6.51174 17.7425C6.77549 16.2794 7.59862 15.0504 8.89237 14.1875C10.0417 13.4216 11.4977 13 12.9924 13C14.4871 13 15.943 13.4219 17.0924 14.1875C18.3861 15.05 19.2092 16.2791 19.473 17.7422C19.5336 18.0775 19.4517 18.4091 19.2486 18.6519C19.1564 18.7625 19.0406 18.8511 18.9098 18.9114C18.779 18.9716 18.6364 19.0019 18.4924 19Z"
      fill="#222F5A"
    />
  </svg>
</template>

<script>
export default {};
</script>

<style>
</style>