<template>
    <div class="ffamily-pro font-semibold flex items-center p-3 rounded-lg fixed  "
    :class="[position, bgToast]">
        <span>
            <slot name="icon">
              <Icontoasche />
            </slot>
        </span>
        <span class="ml-2 text-white">
            <slot></slot>
        </span>
            
    </div>
</template>

<script>

import Icontoasche from "../../assets/svg/icons/toascheck.svg?inline";
export default {
    components:{
        Icontoasche
    },
    props: {
        bgToast: {
            type: String,
            default: 'bg-bgk-3'
        },
        position: {
            type: String,
            default: '-mt-12'
        }
    }
}
</script>

<style scoped>

</style>