<template>
  <div class="flex row items-center space-x-2">
    <div><slot name="icon"></slot></div>
    <div><span class="font-semibold">{{ title }}</span>: <span class="" :title="amount">{{ amount }}</span></div>
  </div>
</template>

<script>
export default {
  props: ["title", "amount"],
};
</script>

<style scoped>
.ctn-item {
  display: flex;
  align-items: center;
  gap: 5px;
  margin-top: 5px;
}
.title {
  color: #000;
  font-weight: 900;
}
</style>