<template>
  <svg
    width="25"
    height="24"
    viewBox="0 0 25 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle cx="12.9922" cy="12" r="12" fill="#E6ECF4" />
    <path
      d="M5.03763 9.70325C5.03763 9.70325 9.37136 2.15387 9.4899 2.10264C9.60844 2.0514 11.4709 2.55972 12.6212 3.43973L14.9568 2.00117C14.9568 2.00117 15.228 1.9841 15.3466 2.08556C15.4651 2.18702 16.328 3.35534 16.6334 3.89681L21.3901 5.50513C21.3901 5.50513 21.7628 5.94513 21.7628 6.45345C21.7628 6.96176 16.312 21.5361 16.312 21.5361C16.312 21.5361 15.432 21.9259 15.1949 21.7732C14.9578 21.6205 8.96552 19.6747 8.76259 19.3361C8.55967 18.9976 8.25427 18.5405 8.62697 17.7961C8.99967 17.0517 10.608 12.2438 10.608 12.2438C10.608 12.2438 7.61135 10.7882 5.22548 10.1453C5.22448 10.1433 4.86886 9.99759 5.03763 9.70325Z"
      fill="#FDFEFA"
    />
    <path
      d="M16.7558 17.0334C16.4303 16.0579 15.9049 15.187 15.1605 14.5038C14.4231 13.8268 13.6104 13.216 12.7666 12.6765C10.9935 11.5434 9.05369 10.7497 7.07568 10.0475C6.66983 9.9039 6.26298 9.76024 5.81494 9.60152C6.18563 8.94955 6.53824 8.31566 6.9039 7.68881C7.7598 6.22113 8.62876 4.76047 9.47561 3.28777C9.6484 2.9884 9.78703 2.84877 10.1698 2.99543C11.3441 3.44247 12.3788 4.11252 13.3613 4.877C15.1515 6.27135 16.1771 8.15091 16.7367 10.3037C16.8974 10.9215 16.9567 11.5715 17.0029 12.2104C17.0632 13.0331 17.1023 13.8609 17.0772 14.6847C17.0541 15.4622 16.9346 16.2367 16.8582 17.0123C16.8241 17.0193 16.7899 17.0263 16.7558 17.0334ZM11.1754 4.9212C9.62931 4.83381 8.29122 6.15683 8.28519 7.74808C8.27917 9.33129 9.58913 10.6704 11.2607 10.6171C12.639 10.5729 14.0243 9.40764 14.0314 7.76616C14.0374 6.25629 12.7716 4.84787 11.1754 4.9212Z"
      fill="#222F5A"
    />
    <path
      d="M11.388 12.6236C12.0761 13.0676 12.7411 13.4715 13.38 13.9135C14.5142 14.6961 15.4886 15.6333 15.9618 16.9654C16.1155 17.3974 16.1496 17.8816 16.1778 18.3457C16.1928 18.5858 16.0874 18.7877 15.7287 18.6531C14.1204 18.0513 12.502 17.4737 10.8857 16.8921C9.98658 16.5686 9.94841 16.5083 10.2719 15.6173C10.6235 14.6529 10.9881 13.6955 11.388 12.6236Z"
      fill="#DF001B"
    />
    <path
      d="M16.6341 3.89669C17.9873 4.42711 19.464 4.98766 20.8453 5.4367C21.4792 5.64264 21.5977 6.20018 21.4149 6.70548C20.4867 9.2782 19.5524 11.8489 18.6151 14.4186C17.8175 16.6056 17.0138 18.7905 16.2102 20.9745C16.0022 21.54 15.6446 21.7249 15.08 21.522C13.1543 20.8318 11.2436 20.0975 9.30574 19.4435C8.54829 19.1873 8.4629 18.6509 8.67085 18.0501C8.99633 17.1089 9.35295 16.1786 9.6935 15.2434C10.0461 14.275 10.3957 13.3055 10.7513 12.3231C10.6067 12.2618 10.4841 12.2106 10.3324 12.1463C10.1034 12.7711 9.87835 13.3849 9.65433 13.9977C9.14501 15.391 8.62062 16.7794 8.1334 18.1817C7.87322 18.9312 8.1987 19.5218 8.95414 19.7991C9.69752 20.0724 10.4409 20.3466 11.1853 20.6158C12.4631 21.0779 13.7389 21.5491 15.0238 21.9911C15.7631 22.2452 16.3619 21.9469 16.617 21.2527C17.486 18.8849 18.36 16.5202 19.2239 14.1504C20.122 11.6892 21.0141 9.22696 21.8961 6.76073C21.9764 6.53671 22.0096 6.27452 21.9835 6.03945C21.9192 5.46182 21.5143 5.18054 21.001 4.9977C19.9291 4.61697 18.8643 4.21715 17.7944 3.82838C17.278 3.64052 16.7587 3.4597 16.2403 3.27586L16.6341 3.89669Z"
      fill="black"
    />
    <path
      d="M18.1541 13.6912C18.5549 10.6404 18.349 7.67887 17.041 4.79172C17.8678 5.09209 18.6574 5.37839 19.446 5.66469C19.7242 5.76616 20.0015 5.87063 20.2808 5.96908C20.7358 6.13082 20.8212 6.3247 20.6535 6.78479C19.883 8.90344 19.1155 11.0231 18.348 13.1428C18.2827 13.3256 18.2184 13.5084 18.1541 13.6912Z"
      fill="black"
    />
    <path
      d="M17.5586 10.6506C16.8835 7.83475 15.5967 5.49912 13.1807 3.85161C13.7874 3.48494 14.346 3.14741 14.9387 2.78877C16.7429 5.09226 17.5727 7.69713 17.5586 10.6506Z"
      fill="#222F5A"
    />
    <path
      d="M12.6456 19.8549C12.1876 19.8619 11.842 19.5324 11.837 19.0814C11.8319 18.6655 12.1805 18.2998 12.5984 18.2817C12.9701 18.2657 13.4001 18.6715 13.4121 19.0502C13.4242 19.446 13.0404 19.8489 12.6456 19.8549Z"
      fill="black"
    />
    <path
      d="M13.749 19.9682C13.8515 19.6839 13.9489 19.4107 14.0494 19.1324C14.5306 19.3022 14.9977 19.488 15.4769 19.6297C15.8054 19.7261 15.8888 19.9139 15.7963 20.2113C15.7019 20.5147 15.4809 20.6161 15.1645 20.5006C14.6853 20.3268 14.2091 20.142 13.749 19.9682Z"
      fill="black"
    />
    <path
      d="M11.4592 18.1798C11.3598 18.4651 11.2644 18.7384 11.1639 19.0277C10.6455 18.8439 10.1191 18.6761 9.61082 18.4651C9.44607 18.3968 9.26625 18.2682 9.41091 17.993C9.53548 17.7549 9.58169 17.4957 9.95238 17.6394C10.4446 17.8312 10.9459 17.997 11.4592 18.1798Z"
      fill="black"
    />
    <path
      d="M9.90465 9.21143C9.44154 8.75535 10.0885 8.16064 10.0885 8.16064L9.85241 7.92155C9.02564 8.61471 9.32802 9.4465 10.0804 9.81317C10.6922 10.1115 11.1503 9.39728 11.1845 9.19937L10.9424 8.98138C10.9424 8.98138 10.5556 9.91262 9.93779 9.2496L9.90465 9.21143Z"
      fill="#016185"
    />
    <path
      d="M10.1284 7.52132L11.4555 8.78809L11.6825 8.50078L11.128 7.9563L11.4605 7.46607L11.2174 7.2089L10.8457 7.76845L10.5584 7.45703L10.9652 6.88743L10.7221 6.62926L10.1284 7.52132Z"
      fill="#016185"
    />
    <path
      d="M12.1194 7.76175L11.0938 6.11123L11.3499 5.65114L12.9311 6.51608L12.7423 6.81343L12.3013 6.59946L12.0129 7.0033L12.3233 7.44933L12.1194 7.76175Z"
      fill="#016185"
    />
    <path
      d="M11.8514 6.77415L11.4185 6.11012L12.0996 6.42857L11.8514 6.77415Z"
      fill="#FDFEFA"
    />
  </svg>
</template>

<script>
export default {};
</script>

<style>
</style>